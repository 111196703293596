import { useTheme } from "@mui/styles";
import {
  useUpdatePatient,
  useUpdateSearchCandidates,
} from "apollo/hooks/mutations";
import {
  useGetPatientQuery,
  useGetStaticSearchCandidates,
} from "apollo/hooks/queries";
import {
  CANDIDATES_STATUS_SUCCESS,
  QUERY_PROGRESS_NOT_STARTED,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOME_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_REHABILITATION,
  SEARCH_TYPE_TRANSPORT,
} from "core/consts";
import {
  GetNameOptions,
  convertSocialWorkers,
  getAccountValue,
} from "core/model/accounts";
import { getPatientName } from "core/model/patients";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import {
  Account,
  Auction,
  AuctionNoPatient,
  Patient as PatientType,
  SearchType,
} from "core/types";
import { PersonIcon } from "ds/icons";
import { DotWithBorder } from "ds_legacy/components/Dot";
import RSButton from "ds_legacy/components/RSButton";
import { SelectOption } from "ds_legacy/components/Select/types";
import {
  ICON_DARK,
  SUB_HEADER_BAR_BACKGROUND,
  WHITE,
} from "ds_legacy/materials/colors";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import {
  APP_BAR_HEIGHT,
  PATIENT_MENU_HEIGHT,
  Z_INDEX_PATIENT_MENU,
  border,
  dp,
  margin,
  padding,
  sizing,
} from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_16,
  FONT_WEIGHT_BOLD,
  Title,
} from "ds_legacy/materials/typography";
import {
  usePatientEncryption,
  usePrint,
  useSenderAccountContext,
} from "dsl/atoms/Contexts";
import {
  shouldDisplayCandidates,
  useCandidates,
} from "dsl/atoms/SearchCandidates";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import SocialWorkerChange, {
  differentAssignee,
} from "dsl/ecosystems/PatientAssessment/SocialWorkerChange";
import { useCareseekerNavigationHandlers } from "dsl/hooks/useNavigationHandlers";
import {
  PRODUCT_TOURS,
  TourAttributeWrapper,
} from "dsl/molecules/useProductTour";
import { ArchivePatient } from "dsl/organisms/DeletePatientButton";
import { debounce } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Outlet } from "react-router-dom";
import styled, { CSSProperties } from "styled-components";
import { useTranslations } from "translations";
import Translations from "translations/types";
import AddSearchDropDown from "./AddSearch/AddSearchDropdown";
import SocialWorkerAutoComplete from "./SocialWorkerAutocomplete";
import {
  FieldsHolderType,
  extractPatientFields,
  getAuctionFromFields,
} from "./transform";

export type WatchedFieldContextType = {
  setWatchedFields: Dispatch<SetStateAction<FieldsHolderType>>;
  watchedFields: FieldsHolderType | null;
};

const PatientMenuChildrenWrapper = styled.div<{ print?: boolean }>`
  display: ${(props) => (props.print ? "block" : "flex")};
  flex-direction: column;
`;

const EmptyBlock = styled.div`
  z-index: 0;
  height: ${dp(PATIENT_MENU_HEIGHT)};
  width: 100%;
`;

const PatientMenuContainer = styled.div<{
  storybookPosition?: CSSProperties["position"];
}>`
  background-color: ${WHITE};
  box-sizing: border-box;
  height: ${dp(PATIENT_MENU_HEIGHT)};
  position: ${({ storybookPosition }) => storybookPosition || "fixed"};
  top: ${dp(APP_BAR_HEIGHT)};
  width: 100vw;
  z-index: ${Z_INDEX_PATIENT_MENU};
`;

const EditIconWrapper = styled.span`
  display: none;
  margin-right: ${dp(8)};
`;

const InfoStripe = styled.div`
  display: flex;
  width: 100%;
  height: ${sizing(8.5)};
  max-height: ${sizing(8.5)};
  justify-content: space-between;
  align-items: center;
  overflow-y: hidden;
  ${({ onClick }) =>
    onClick &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${SUB_HEADER_BAR_BACKGROUND};
    }
  `};
  &:hover ${EditIconWrapper} {
    ${({ onClick }) => onClick && `display: inline-block;`};
  }
`;

const InfoWrapper = styled.span`
  display: flex;
  margin-bottom: ${dp(4)};
  align-items: center;
`;

const TabsContainer = styled.div<{ justify?: string }>`
  display: flex;
  justify-content: ${({ justify }) => justify ?? `space-between`};
  align-items: center;
  padding: ${padding(0, 3, 0, 1)};
  overflow-y: hidden;
  box-sizing: border-box;
  height: ${sizing(5.5)};
  border-bottom: ${border({ color: "rgba(0, 0, 0, 0.12)" })};
`;

export const DotContainer = styled.span`
  display: flex;
  justify-content: center;
  margin: ${margin(0, 1)};
`;

function isValidPatient(
  patient: PatientType | undefined,
  auction: Auction | undefined,
): boolean {
  return (
    auction?.solutions != null &&
    auction.solutions.length > 0 &&
    auction?.profile?.search_location?.latitude != null &&
    auction?.profile?.search_location?.longitude != null &&
    patient?.profile != null &&
    auction?.start_date != null &&
    (patient?.profile.age != null || patient?.profile.age_interval != null)
  );
}

export function PatientIdentifier({ patient }: { patient: PatientType }) {
  const { encryptionAvailable } = usePatientEncryption();
  const patientName = getPatientName(patient);

  if (encryptionAvailable && patientName)
    return (
      <Title whiteSpace="nowrap">
        <span
          data-testid="patient_name"
          style={{ fontWeight: FONT_WEIGHT_BOLD }}
        >
          {patientName}
        </span>
        &nbsp;<span data-testid="patient_id">({patient.user_id})</span>
      </Title>
    );

  return (
    <Title whiteSpace="nowrap">
      Patient&nbsp;
      <span data-testid="patient_id">{patient.user_id || ""}</span>
    </Title>
  );
}

export function PatientAssignee({ patient }: { patient: PatientType }) {
  const toast = useToast();
  const [open, setOpen] = useState(false);
  const nameOptions: GetNameOptions = {
    withSalutation: false,
    withAcademicTitle: false,
  };
  const translations = useTranslations();
  const getOntology = useGetOntology();
  const { account, socialWorkers } = useSenderAccountContext();
  const assignee = patient.social_worker;

  const currentValue = useMemo(() => {
    return getAccountValue(assignee, getOntology, nameOptions);
  }, [assignee, nameOptions]);

  const assigneeRef = useRef<Account | undefined>(assignee);

  const options = useMemo(
    () =>
      convertSocialWorkers(socialWorkers, getOntology, nameOptions).reduce<
        SelectOption[]
      >((acc, { name, value }) => {
        if (name && value) {
          return [...acc, { label: name, value, id: value }];
        }
        return acc;
      }, []),
    [socialWorkers, translations, nameOptions],
  );

  const [updatePatient, mutationProgress, resetProgress] = useUpdatePatient({
    id: patient.id,
    onError: () =>
      toast({
        message: translations.auctionRequest.uploadError,
        color: "primary",
      }),
    onCompleted: ({ patient: newPatient }) => {
      const oldAssignee = assigneeRef?.current;
      const [shouldNotify] = differentAssignee(
        newPatient || patient,
        { ...patient, social_worker: oldAssignee },
        account,
      );

      if (shouldNotify) setOpen(true);
      else resetProgress();

      if (assigneeRef?.current != null) assigneeRef.current = assignee;
    },
  });

  const onChange = useCallback(
    (newValue: SelectOption) => {
      const social_worker = socialWorkers?.find(({ id }) => id === newValue.id);
      if (social_worker != null) {
        updatePatient({ social_worker });
      }
    },
    [updatePatient, socialWorkers],
  );

  return (
    <>
      <HorizontalLayout
        data-testid="patient-assignee"
        aligned
        style={{
          maxHeight: "100%",
          minWidth: sizing(35),
          margin: margin(0, 2, 0.375, 2),
        }}
      >
        <PersonIcon
          style={{
            color: ICON_DARK,
            fontSize: FONT_SIZE_16,
            margin: margin(0, 0.5, 0, 0),
          }}
          size={FONT_SIZE_16}
        />
        <Body margin={margin(0, 0, 1 / 8, 0)}>
          {translations.patient.responsiblePerson}
          {translations.general.colon}
          &nbsp;
        </Body>
        <SocialWorkerAutoComplete
          currentValue={currentValue}
          defaultValue={
            options.find(({ id }) => id === currentValue) as SelectOption
          }
          disabled={mutationProgress !== QUERY_PROGRESS_NOT_STARTED}
          onChange={onChange}
          options={options}
        />
      </HorizontalLayout>
      {open && assignee != null && (
        <SocialWorkerChange
          assigned={assignee}
          patientId={patient?.id}
          nextNotification={() => {
            setOpen(false);
            resetProgress();
          }}
        />
      )}
    </>
  );
}

const TabContainer = styled.div<{ isActive: boolean }>`
  border-bottom: ${(props) =>
    props.isActive
      ? border({ width: 2, color: props.theme.palette.primary.main })
      : "none"};
  margin: ${margin(0, 1)};
`;

export function Tabs({
  auctionId,
  auctions,
  goToAuction,
  translations,
}: {
  auctionId: number | undefined;
  auctions: Array<AuctionNoPatient> | undefined;
  goToAuction: ({
    auctionId,
    patientId,
  }: {
    auctionId: number;
    patientId?: number;
  }) => void;
  translations: Translations;
}) {
  const getTabName = (searchType: SearchType) => {
    switch (searchType) {
      case SEARCH_TYPE_CARE:
        return translations.ontologies.patientType.values.care;
      case SEARCH_TYPE_HOSPITAL:
        return translations.ontologies.patientType.values.hospital;
      case SEARCH_TYPE_REHABILITATION:
        return translations.ontologies.patientType.values.rehab;
      case SEARCH_TYPE_TRANSPORT:
        return translations.ontologies.patientType.values.transport;
      case SEARCH_TYPE_MEDICAL_SUPPLIES:
        return translations.ontologies.patientType.values.medicalSupplies;
      case SEARCH_TYPE_HOME_CARE:
        return translations.ontologies.patientType.values.homeCare;
      default:
        return translations.ontologies.patientType.values.care;
    }
  };
  const theme = useTheme();
  return (
    <TourAttributeWrapper
      tourKey={PRODUCT_TOURS.parallel_search.key}
      stepKey={PRODUCT_TOURS.parallel_search.steps.old_search_tab.key}
    >
      <HorizontalLayout aligned>
        {auctions?.map((auction) => {
          const isActive = auction.id === auctionId;
          const hasStatusDot = !!auction.new_responses;

          const tabName = getTabName(auction.search_type);
          const tabTitle = auction.profile?.has_transitional_care
            ? translations.patientForms.transitionalCareForm.tabTitle({
                tabName,
              })
            : tabName;

          return (
            <TabContainer isActive={isActive} key={auction.id}>
              <RSButton
                color={isActive ? "primary" : "grey"}
                id={getTabName(auction.search_type)}
                loading="na"
                onClick={() => goToAuction({ auctionId: auction.id })}
                style={{
                  margin: margin(0.25, 1),
                  backgroundColor: "transparent",
                  fontWeight: isActive ? FONT_WEIGHT_BOLD : undefined,
                }}
                variant="text"
              >
                {hasStatusDot ? (
                  <DotContainer>
                    {tabTitle}
                    <DotWithBorder color={theme.palette.secondary.main} />
                  </DotContainer>
                ) : (
                  tabTitle
                )}
              </RSButton>
            </TabContainer>
          );
        })}
      </HorizontalLayout>
    </TourAttributeWrapper>
  );
}

function ConnectedPatientMenu({ auction }: { auction: Auction }) {
  const [_, patient] = useGetPatientQuery({
    patientId: auction.patient.id,
    decrypt: true,
  });

  if (!patient) return null;

  return <PatientMenu patient={patient} auction={auction} />;
}

export const getFirstAuction = (
  patient: PatientType,
): AuctionNoPatient | undefined => {
  const auctionsArray: Readonly<Array<Auction>> | undefined = patient.auctions;
  if (!auctionsArray || auctionsArray?.length === 0) return;

  const sortedAuctionsByStartDate = auctionsArray
    .clone()
    .sort(
      (auctionA: AuctionNoPatient, auctionB: AuctionNoPatient) =>
        (auctionA?.start_date ?? 0) - (auctionB?.start_date ?? 0),
    );

  const sortAuctionsByCreatedAt = sortedAuctionsByStartDate.sort(
    (auctionA: AuctionNoPatient, auctionB: AuctionNoPatient) =>
      (auctionA?.created_at ?? 0) - (auctionB?.created_at ?? 0),
  );
  return sortAuctionsByCreatedAt[0];
};

export default function PatientMenu({
  auction,
  patient,
  storybookPosition,
}: {
  auction: Auction;
  patient: PatientType;
  storybookPosition?: CSSProperties["position"];
}) {
  const translations = useTranslations();
  const appNavigation = useCareseekerNavigationHandlers();

  const firstAuction = getFirstAuction(patient);

  return (
    <PatientMenuContainer storybookPosition={storybookPosition}>
      <InfoStripe>
        <HorizontalLayout aligned margin={margin(0, 0, 0.5, 0)}>
          <PatientIdentifier patient={patient} />
          <ArchivePatient patient={patient} translations={translations} />
        </HorizontalLayout>
        <InfoWrapper>
          <PatientAssignee patient={patient} />
        </InfoWrapper>
      </InfoStripe>
      <TabsContainer justify="flex-start">
        <Tabs
          translations={translations}
          auctions={patient.auctions}
          goToAuction={appNavigation.patient.goToAuction}
          auctionId={auction.id}
        />
        {firstAuction ? (
          <AddSearchDropDown firstAuction={firstAuction} patient={patient} />
        ) : null}
      </TabsContainer>
    </PatientMenuContainer>
  );
}

export const handleSolutionsFromForm = (
  newAuction: Auction,
  auction: Auction,
) => {
  // for transport auctions solutions is a number this is a
  // corner case and needs to be returned to a number array
  let solutions = newAuction?.solutions ?? auction?.solutions ?? null;

  if (typeof solutions === "number") {
    solutions = [solutions];
  }
  return solutions;
};

export const useExtracted = (auction: Auction) => {
  const extractedMutable = useMemo(
    () => extractPatientFields(auction),
    [auction],
  );
  const [extracted, setExtracted] = useState(extractedMutable);
  useEffect(() => {
    const changesExist =
      // extractedMutable is pretty shallow so this is fine
      JSON.stringify(extractedMutable) != JSON.stringify(extracted);

    if (changesExist) setExtracted(extractedMutable);
  }, [extractedMutable, extracted]);

  return extracted;
};

export const WatchedFieldContext = createContext<WatchedFieldContextType>({
  watchedFields: null,
  setWatchedFields: () => {},
});

export const useWatchedFields = () => {
  const context = useContext(WatchedFieldContext);

  if (context === undefined) {
    throw new Error(
      "useWatchedFields must be used within a WatchedFieldProvider",
    );
  }

  return context;
};

export function AssessmentPatientMenu({
  auction,
  children,
  patient,
}: {
  auction: Auction;
  children?: React.ReactNode;
  patient: PatientType;
}) {
  const [watchedFields, setWatchedFields] = useState<FieldsHolderType>(() =>
    extractPatientFields(auction),
  );

  useEffect(() => {
    if (patient != null) {
      setWatchedFields(extractPatientFields(auction));
    }
  }, [patient, setWatchedFields]);

  return (
    <WatchedFieldContext.Provider
      value={{
        watchedFields,
        // don't change this line unless you understand
        // the FormWatcher w/ setState in context implications
        setWatchedFields: (fields) => setWatchedFields(cloneDeep(fields)),
      }}
    >
      <EnhancedFormPatientMenu
        auction={auction}
        watchedFields={watchedFields}
      />

      <PatientMenuChildrenWrapper>
        <EmptyBlock />
        {children ? children : <Outlet />}
      </PatientMenuChildrenWrapper>
    </WatchedFieldContext.Provider>
  );
}

function EnhancedPatientMenu({ auction }: { auction: Auction }) {
  const [getSearchCandidates] = useGetStaticSearchCandidates({
    auctionId: auction.id,
  });
  const extracted = useExtracted(auction);

  const { setCandidates } = useCandidates();

  useEffect(() => {
    (async () => {
      const newAuction = auction;
      if (
        isValidPatient(newAuction.patient, auction) &&
        shouldDisplayCandidates(auction) &&
        auction.candidates_status === CANDIDATES_STATUS_SUCCESS
      ) {
        const result = await getSearchCandidates();
        if (result?.data) {
          setCandidates({
            services: result.data.searchCandidates?.services || [],
            candidates: result.data.searchCandidates?.count || 0,
            patientId: auction.patient?.id ?? -1,
          });
        }
      } else {
        setCandidates({
          services: [],
          candidates: undefined,
          patientId: auction.patient?.id ?? -1,
        });
      }
    })();

    // Will rerender when encrypted fields are decrypted
    // This can probably be optimized in the future
  }, [extracted, auction.status, auction.candidates_status]);

  return <ConnectedPatientMenu auction={auction} />;
}

function EnhancedFormPatientMenu({
  auction,
  watchedFields,
}: {
  auction: Auction;
  watchedFields: FieldsHolderType;
}) {
  const [updateSearchCandidates, , , data] = useUpdateSearchCandidates();
  const { setCandidates } = useCandidates();

  const debouncedUpdateSearchCandidates = useCallback(
    debounce((auction: Auction, newAuction: Auction) => {
      updateSearchCandidates({
        ...newAuction,
        start_date: newAuction.start_date ?? auction.start_date,
        id: auction.id,
        solutions: handleSolutionsFromForm(newAuction, auction) ?? [],
        patient: {
          ...newAuction.patient,
          id: auction.patient?.id,
        },
      });
    }, 500),
    [updateSearchCandidates, handleSolutionsFromForm],
  );

  useEffect(() => {
    const newAuction = getAuctionFromFields(watchedFields);
    if (
      isValidPatient(newAuction.patient, auction) &&
      shouldDisplayCandidates(auction)
    ) {
      debouncedUpdateSearchCandidates(auction, newAuction);
    } else {
      setCandidates({
        services: [],
        candidates: undefined,
        patientId: auction.patient?.id ?? -1,
      });
    }
  }, [watchedFields, auction]);

  useEffect(() => {
    if (data) {
      setCandidates({
        services: data.searchCandidates?.services || [],
        candidates: data.searchCandidates?.count || 0,
        patientId: auction.patient?.id ?? -1,
      });
    }
  }, [data]);

  return <ConnectedPatientMenu auction={auction} />;
}

export function PatientMenuStatic({ auction }: { auction: Auction }) {
  const print = usePrint();

  return (
    // We need to handle print this way to avoid refetching / remounting on print
    <>
      {!print && <EnhancedPatientMenu auction={auction} />}
      <PatientMenuChildrenWrapper print={print}>
        {!print && <EmptyBlock />}
        <Outlet />
      </PatientMenuChildrenWrapper>
    </>
  );
}
